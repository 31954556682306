import { Formik } from "formik";
import React from "react";
import { Radio } from "@components/atoms";
import * as S from "./styles";
export var statuses = [{
  token: "charged",
  label: "Charged"
}, {
  token: "fully-refunded",
  label: "Fully refunded"
}, {
  token: "not-charged",
  label: "Not charged"
}];
/**
 * Dummy payment gateway.
 */

var DummyPaymentGateway = function DummyPaymentGateway(_ref) {
  var processPayment = _ref.processPayment,
      formRef = _ref.formRef,
      formId = _ref.formId,
      initialStatus = _ref.initialStatus;
  return React.createElement(Formik, {
    initialValues: {
      status: initialStatus || statuses[0].token
    },
    onSubmit: function onSubmit(values, _ref2) {
      var setSubmitting = _ref2.setSubmitting;
      processPayment(values.status);
      setSubmitting(false);
    }
  }, function (_ref3) {
    var handleChange = _ref3.handleChange,
        handleSubmit = _ref3.handleSubmit,
        handleBlur = _ref3.handleBlur,
        values = _ref3.values,
        isSubmitting = _ref3.isSubmitting,
        isValid = _ref3.isValid;
    return React.createElement(S.Form, {
      id: formId,
      ref: formRef,
      onSubmit: handleSubmit,
      className: "checkout-payment-form",
      "data-test": "dummyPaymentGatewayForm"
    }, statuses.map(function (_ref4) {
      var token = _ref4.token,
          label = _ref4.label;
      return React.createElement(S.Status, {
        key: token
      }, React.createElement(Radio, {
        key: token,
        type: "radio",
        name: "status",
        value: token,
        checked: values.status === token,
        onChange: handleChange
      }, React.createElement("span", null, label)));
    }));
  });
};

export { DummyPaymentGateway };